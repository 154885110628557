.circle {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .pinecone {
    height: 80%;
    transform: translateY(-2%);
  }
}
