// CREDIT TO @author Jonathan Suh @jonsuh
// @site https://jonsuh.com/hamburgers
// @link https://github.com/jonsuh/hamburgers

.hamburger {
  position: fixed;
  top: 5rem;
  padding: 0 !important;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter, background-color;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  z-index: var(--hamburger-z);
  transition: transform 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1);

  &:active,
  &:focus {
    outline: none;
  }

  @media only screen and (max-height: 750px) {
    top: 4rem;
  }

  --menu-before-d: 60px;

  &::before {
    content: "";
    position: absolute;
    top: -6.9rem;
    left: -1.4rem;
    display: block;
    height: var(--menu-before-d);
    max-height: var(--menu-before-d);
    width: var(--menu-before-d);
    max-width: var(--menu-before-d);
    border-radius: 50%;
    transform: translateY(5.6rem);
    background-color: var(--pink-main);
    opacity: 0.8;
    transition: all 1s;

    @media screen and (max-width: 1150px) {
      top: -7.2rem;
      left: -1.7rem;
    }

    @media screen and (max-width: 900px) {
      top: -7.5rem;
      left: -2rem;

      @media screen and (max-height: 500px) {
        top: -7.9rem;
        left: -2.4rem;
      }
    }
  }
}

.hamburger.isActive {
  z-index: var(--hamburger-active-z);

  &::before {
    content: none;
  }
}

.hamburger.isActive .hamburger-inner,
.hamburger.isActive .hamburger-inner::before,
.hamburger.isActive .hamburger-inner::after {
  background-color: currentColor;
}

.hamburger-box {
  width: 33px;
  height: 25px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.hamburger-inner {
  display: block;
  top: 50%;
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 33px;
  height: 5px;
  background-color: currentColor;
  position: absolute;
  transition-property: transform, background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger-inner::after {
  bottom: -10px;
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0.1s linear;
}

.hamburger:hover:not(.isActive) {
  transform: scale(1.05);

  &::before {
    opacity: 1;
  }
}

.hamburger.isActive .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.isActive .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s 0.22s linear;
}
.hamburger.isActive .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.isActive:hover {
  transform: scale(1.1);
}
