.articlePage {
  overflow: hidden;
}

.socialsOuter {
  display: inline-block;
  margin: 5rem auto 4rem auto;

  .socialsContainer {
    position: relative;
    display: flex;
    height: 30px;
    width: 140px;
    justify-content: flex-start;

    & > a {
      position: relative;
      margin: 0 auto;
      flex: 1 1 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 7px;
      transition: all 0.1s ease-in;
      cursor: pointer;

      &:hover {
        flex: 2 2 50px;
      }

      & > svg {
        overflow: visible;
        flex: 1 1;
        height: auto;
        width: 33.3333%;
      }
    }
  }
}

.heroContainer {
  height: 52rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-lightest);

  &.test {
    height: 30vw;
    min-height: 35rem;
    display: block;
  }

  @keyframes growIn {
    0% {
      opacity: 0.7;
      transform: scale(1.05);
      filter: blur(2px);
    }
    100% {
      opacity: 1;
      transform: scale(1);
      filter: none;
    }
  }

  .hero {
    --width: 2000px;

    @media screen and (max-width: 1150px) {
      --width: 1150px;
    }

    position: absolute;
    display: block;
    width: var(--width);
    min-width: var(--width);
    height: auto;
    bottom: -28rem;
    opacity: 0;

    &.test {
      display: block;
      position: relative;
      min-width: initial;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &.appear {
      animation: growIn 1.5s ease-out;
      opacity: 1;
    }

    @media screen and (max-width: 1150px) {
      bottom: -15rem;
    }

    @media screen and (max-width: 900px) {
      bottom: -20rem;

      @media screen and (max-height: 500px) {
        bottom: -25rem;
      }
    }
  }
}

.article {
  padding: calc(var(--section-padding-y) * 0.6) var(--section-padding-x);

  & > * {
    max-width: var(--max-content-width);
    margin: 0 auto;
  }

  .articleContent {
    @keyframes fallIn {
      0% {
        opacity: 0;
        transform: translate3d(0, -10px, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    .goBack {
      color: currentColor;
      background-color: transparent;
      transform: translateY(calc(-1 * var(--section-padding-y) / 2 + 1rem));
      font-weight: 100;

      &:hover {
        span {
          width: 3rem;
        }
      }

      span {
        display: inline-block;
        height: 1px;
        width: 2rem;
        background-color: currentColor;
        transform: translateY(-0.7rem);
        margin-right: 0.5rem;
        transition: all 0.2s;

        &::before {
          content: "";
          position: absolute;
          height: 0.5rem;
          width: 0.5rem;
          top: 0;
          left: 0;
          transform-origin: center;
          transform: translateY(-0.2rem) translateX(-0.1rem) rotate(45deg);
          border-left: 1px solid currentColor;
          border-bottom: 1px solid currentColor;
        }
      }
    }

    .header {
      border-bottom: 1px solid currentColor;
      padding-bottom: 5rem;
      margin-bottom: 10rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      animation: fallIn 1s ease-out;

      @media screen and (max-width: 450px) {
        flex-flow: column nowrap;
      }

      h1 {
        font-size: 4rem;
        text-transform: uppercase;
        font-weight: 600;
        max-width: 60%;
        letter-spacing: 0.1em;
        line-height: 1em;

        @media screen and (max-width: 450px) {
          width: 100%;
          max-width: 100%;
          order: 2;
        }
      }

      time {
        h2 {
          text-align: right;
          color: var(--pink-main);
          font-size: 3rem;
          font-weight: 100;

          @media screen and (max-width: 450px) {
            text-align: left;
            order: 1;
            margin-bottom: 3rem;
            line-height: 1.2;
          }
        }
      }
    }

    .articleOuter {
      border-bottom: 1px solid currentColor;
      position: relative;
      overflow: visible;
      padding: 0;

      .articleMain {
        padding-bottom: 11rem;
        margin-top: -2rem;

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        & > * {
          animation: fadeIn 1s 0.2s ease-out backwards;
        }

        h1,
        h2,
        h3,
        h4 {
          text-transform: uppercase;
          font-weight: 600;
        }

        h2 {
          margin: 5rem 0 !important;
        }

        h3 {
          margin: 4rem 0 !important;
        }

        h4 {
          margin: 2rem 0 !important;
        }

        ul,
        ol {
          padding-left: 2.5rem;
          //margin: 3rem;

          @media screen and (max-width: 500px) {
            padding-left: 1.5rem;
          }
        }

        ul {
          li {
            &::before {
              content: "\2022    ";
              content: "~   ";
            }
          }
        }

        ol {
          counter-reset: count;

          li {
            counter-increment: count;
            &::before {
              content: counter(count) ". ";
            }
          }
        }

        p,
        blockquote,
        ul,
        ol {
          margin-bottom: 3rem;

          @media screen and (max-width: 450px) {
            font-size: 2.6rem;
          }
        }

        blockquote {
          margin: 7rem auto;
          text-align: center;
          p {
            margin-bottom: 0;

            &::before {
              content: "\201C";
            }
            &::after {
              content: "\201D";
            }
          }
          cite {
            font-size: 2.3rem;
            &::before {
              content: "- ";
            }
          }
        }

        a {
          text-decoration: underline;
        }

        figure {
          margin: 8rem 0;

          img {
            width: 100%;
            height: auto;
            margin-bottom: 1rem;

            @media screen and (max-width: 450px) {
              width: 100vw;
              transform: translateX(calc(-1 * var(--section-padding-x)));
            }
          }

          figcaption {
            @media screen and (max-width: 450px) {
              font-size: 2.5rem;
            }
          }
        }

        & > * {
          margin-bottom: 0;
          &:last-child {
            margin-bottom: 6rem !important;
          }
        }
      }
    }

    --height: 60px;
    --margin: 30px;
    --offset: calc(var(--height) + var(--margin));

    // @media screen and (max-width: 500px) {
    //   --margin: 80px;
    // }

    .buttonWrapper {
      transition: opacity 0.3s ease-in;
      position: relative;

      &.hide {
        visibility: hidden;
        opacity: 0;
      }

      .buttonContainer {
        position: fixed;
        left: 50vw;
        //top: calc(100vh - var(--offset));
        bottom: var(--margin);
        transform: translateX(-50%);
        height: var(--offset);
        max-height: var(--offset);
        width: var(--max-content-width);
        max-width: calc(100vw - 2 * var(--section-padding-x));
        color: white;
        transition: visibility 5s ease-in, opacity 0.1s ease-in,
          color 0.1s ease-in;
        z-index: var(--scroll-up-z);
        line-height: 1;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: 500px) {
          //top: initial;
          bottom: var(--margin);
        }

        @media screen and (max-width: 500px) {
          opacity: 0.9;
          color: var(--pink-main);

          &:hover:not(:focus) {
            opacity: 1;
            color: var(--pink-main);
          }
        }

        @media screen and (min-width: 501px) {
          &:hover {
            opacity: 1;
            color: var(--pink-main);
          }
        }

        &.stuck {
          //top : calc(var(--offset) * -1);
          bottom: var(--margin);
          position: absolute;
          left: 0;
          margin: 0 auto;
          transform: none;
        }

        .button {
          position: relative;
          width: var(--height);
          height: var(--height);
          background-color: var(--grey-main);
          border-radius: 50%;
          transition: opacity 0.2s ease-in;

          @media screen and (min-width: 900px) {
            opacity: 0.8;

            &:hover {
              opacity: 1;
            }
          }

          --chevron-l: 40%;

          &::after,
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            height: var(--chevron-l);
            width: var(--chevron-l);
            border-left: 4px solid var(--pink-main);
            border-top: 4px solid var(--pink-main);
            transform-origin: 0% 100%;
            z-index: var(--hamburger-z);
          }

          &::after {
            transform: translate3d(-17px, 4px, 0) rotate(45deg);
          }

          &::before {
            transform: translate3d(-17px, 19px, 0) rotate(45deg);
          }
        }
      }
    }

    .foot {
      text-align: center;
      font-size: 16px;
      padding: 9rem 0;

      @media screen and (max-width: 450px) {
        font-size: 2rem;
      }

      img {
        margin: 8rem 0 3rem 0;
      }

      h2 {
        font-size: 3rem;
        font-weight: 600;
        letter-spacing: 0.1em;
        margin-bottom: 3rem;
      }
    }

    .head {
      display: flex;
      width: 100%;
      flex-flow: column nowrap;
      align-items: center;
      padding: 15rem;
      padding-bottom: 10rem;

      @keyframes expand {
        from {
          letter-spacing: 2px;
        }
        to {
          letter-spacing: 4px;
        }
      }

      h1 {
        font-size: 3rem;
        margin-bottom: 4rem;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-align: center;

        &.loading {
          animation: expand 7s ease-in-out infinite alternate,
            halfFadeIn 1s infinite ease-out alternate;
        }
      }

      @keyframes vert {
        0% {
          transform: translate3d(0, -10px, 0);
        }
        100% {
          transform: translate3d(0, 10px, 0);
        }
      }

      @keyframes hor {
        0% {
          transform: translate3d(0px, 0, 0);
        }
        25% {
          transform: translate3d(-20px, 0, 0);
        }
        75% {
          transform: translate3d(20px, 0, 0);
        }
        100% {
          transform: translate3d(px, 0, 0);
        }
      }

      @keyframes hor {
        0% {
          transform: translate3d(-10px, 0, 0);
        }
        100% {
          transform: translate3d(10px, 0, 0);
        }
      }

      @keyframes rot {
        from {
          transform: rotate(7deg);
        }
        to {
          transform: rotate(-7deg);
        }
      }

      @keyframes halfFadeIn {
        from {
          opacity: 0.2;
        }
        to {
          opacity: 1;
        }
      }

      --base: 0.6s;

      .animate1 {
        margin-bottom: 7rem;
        //animation: vert var(--base) infinite ease-in alternate;
        animation: halfFadeIn 1s infinite ease-out alternate;
      }

      .animate2 {
        // animation: hor calc(var(--base) * 5) infinite ease-in-out alternate;
      }

      .animate3 {
        // animation: rot calc(var(--base) * 2.2) ease-in-out infinite alternate;
      }

      svg {
        margin-bottom: 7rem;
      }
    }

    .errorContainer {
      text-align: center;
      text-transform: uppercase;
      margin: 10rem 0;

      .error {
        font-size: 3rem;
        font-weight: 600;
      }

      svg {
        margin: 0 auto;
        margin-top: 5rem;
      }
    }
  }
}
