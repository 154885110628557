.footer {
  background-color: var(--grey-main);
  padding: var(--section-padding-y) var(--section-padding-x);
}

.footerContent {
  color: var(--white);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: var(--max-content-width);
  margin: 0 auto;

  .top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 20px;
    margin-bottom: 5rem;
  }

  .nav {
    font-size: 2rem;
    letter-spacing: 0.15rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.8;

    @media only screen and (max-width: 550px) {
      align-items: center;
      margin: 0 auto;
      margin-bottom: 8rem;
      font-size: 3rem;
    }

    @media only screen and (max-width: 380px) {
      font-size: 2.8rem;
    }

    li {
      display: inline-block;
      cursor: pointer;
      transition: padding 0.2s;

      &:hover {
        padding-left: 0.3rem;
      }
    }

    div {
      height: 1px;
      width: 100%;
      background-color: var(--pink-main);
      margin: 1rem 0;
    }

    .pink {
      color: var(--pink-main);
    }
  }

  .hrline {
    height: 1px;
    width: 100%;
    background-color: var(--pink-main);
    margin: 1rem 0;
  }

  .sig {
    @media only screen and (max-width: 550px) {
      order: -1;
      margin: 0 auto;
      width: 100%;
    }

    @media only screen and (max-width: 450px) {
      width: 45rem;
      height: 45rem;
    }

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 14rem;
      width: 14rem;
      border-radius: 50%;
      background-color: currentColor;

      @media only screen and (max-width: 450px) {
        width: 25rem;
        height: 25rem;
      }

      @media only screen and (max-width: 415px) {
        width: 22rem;
        height: 22rem;
      }
    }

    p {
      font-size: 2.8rem;
      letter-spacing: 6px;
      margin: 3rem 0 3.5rem 0;
      white-space: nowrap;

      @media only screen and (max-width: 550px) {
        font-size: 3.5rem;
      }

      @media only screen and (max-width: 415px) {
        letter-spacing: 4px;
        font-size: 3.2rem;
      }

      @media only screen and (max-width: 380px) {
        letter-spacing: 2px;
        font-size: 2.8rem;
      }
    }
  }

  .form {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 100%;
    margin-top: 4rem;

    @media screen and (max-width: 550px) {
      margin-top: 0;
    }

    h3 {
      flex: 1 1 100%;
      font-size: 2.1rem;
      color: var(--pink-main);
      font-weight: 600;
      margin-bottom: 5rem;

      @media only screen and (max-width: 550px) {
        font-size: 3rem;
        margin-bottom: 3rem;
      }
    }

    .name,
    .email,
    .subject,
    .message {
      @media screen and (max-width: 550px) {
        font-size: 2.5rem;
      }
    }

    input:last-child {
      @media screen and (max-width: 550px) {
        font-size: 2.5rem;
      }
    }

    .name,
    .email {
      flex: 1 1 35rem;
      height: 5rem;
    }

    .name {
      margin-right: 2.5rem;

      @media only screen and (max-width: 608px) {
        margin-right: 0;
        flex: 1 1 40rem;
      }
    }
    .subject {
      flex: 1 1 100%;
    }

    .subject {
      flex: 1 1 100%;
    }

    .message {
      height: 20rem;
      flex: 1 1 100%;
    }

    input,
    textarea {
      background-color: var(--grey-lightest);
      border: 1px solid var(--grey-light);
      padding: 1rem 1.5rem;
      font-family: var(--font-std);
      font-size: 2rem;
      color: var(--grey-main);
      margin-bottom: 1.5rem;
    }

    textarea {
      resize: none;
    }
  }

  .socials {
    margin-top: -3.8rem;
    height: 2.5rem;
    overflow: visible;

    //disable (if enabled, remove margin bottom from divider)
    // display: none;
    // opacity: 0;
    // height: 0;
    // width: 0;
    // visibility: hidden;

    & > *:first-child {
      margin-right: 1rem;
    }

    & > * {
      transition: all 0.1s ease-in;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    @media only screen and (max-width: 900px) {
      margin-top: -4.2rem;
    }

    @media only screen and (max-width: 608px) {
      margin: 4rem 0;
      height: 4rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .divider {
    flex: 1 1 100%;
    height: 1px;
    background-color: var(--grey-lightest);
    // margin-bottom: 6rem;

    // margin-top: 2rem; //disable if socials reintroduced
  }

  .logoWrapper {
    @media only screen and (max-width: 501px) {
      width: 100%;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
    }

    .LFLogo {
      width: 23rem;
      height: auto;
      align-self: flex-start;

      @media only screen and (max-width: 501px) {
        width: 28rem;
      }

      &:hover {
        opacity: 0.6;
        transition: all 0.1s ease-in;
      }
    }
  }

  .refs {
    font-size: 2rem;
    line-height: 1.5;
    align-self: center;
    transform: translateY(1rem);

    text-align: left;

    & > *:hover {
      opacity: 0.6;
      transition: all 0.1s ease-in;
    }

    @media only screen and (max-width: 501px) {
      max-width: 100%;
      width: 100%;
      text-align: center;
    }

    @media only screen and (max-width: 330px) {
      font-size: 1.9rem;
    }

    p {
      margin-bottom: 1em;
    }
  }
}
