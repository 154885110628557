.modalScreen {
  --circle-d: 18rem;
  --top-strip-height: 18rem;
  --cross-height: 33px;

  @media only screen and (max-width: 700px) {
    --top-strip-height: 14rem;
  }

  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: scroll !important;
  overscroll-behavior-y: none !important;
  padding: var(--section-padding-y) var(--section-padding-x) 0
    var(--section-padding-x) !important;
  z-index: var(--modal-z);

  @media only screen and (max-width: 700px) {
    padding: 0 !important;
    margin: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal {
    --mobile-padding-x: 12rem;

    @media screen and (max-width: 600px) {
      --mobile-padding-x: 10rem;
    }

    @media screen and (max-width: 500px) {
      --mobile-padding-x: 6rem;
    }

    @media screen and (max-width: 500px) {
      --mobile-padding-x: 4rem;
    }

    position: relative;
    z-index: var(--modal-z);
    width: 100%;
    max-width: var(--max-content-width);
    background-image: linear-gradient(
      to bottom,
      var(--pink-main) var(--top-strip-height),
      var(--blush-white) var(--top-strip-height)
    );
    margin: 0 auto var(--section-padding-y);
    padding: 0 var(--mobile-padding-x) 20rem var(--mobile-padding-x);
    animation: fadeIn 0.2s backwards ease-out;
    transition: all 0.1s ease-in;

    @media only screen and (max-width: 700px) {
      margin: 0 auto;
    }

    &.unmounting {
      opacity: 0;
    }

    .crossContainer {
      position: fixed;
      height: var(--cross-height);
      left: 4rem;
      top: 4rem;
      z-index: var(--modal-cross-z);
      cursor: pointer;

      @media only screen and (max-width: 900px) {
        left: calc(var(--section-padding-x) / 2);
        left: 2%;
      }

      @media only screen and (max-width: 700px) {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8rem;
        width: 8rem;
        border-radius: 50%;
        top: calc(var(--top-strip-height) / 2);
        left: calc(var(--mobile-padding-x) - 13px);
        transform: translate3d(0, -50%, 0);
        background-color: rgba(var(--pink-main-rgb), 0.8);
        transition: all 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }

    .modalContent {
      width: 100%;
      //max-width: 69rem;
      margin: 0 auto;

      .sig {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;

        @media screen and (max-width: 900px) {
          margin-bottom: 8rem;
        }

        @media only screen and (max-width: 500px) {
          padding-bottom: 0;
        }

        .circle {
          margin-top: calc(var(--top-strip-height) - var(--circle-d) / 2);
          width: var(--circle-d);
          height: var(--circle-d);
        }

        h1 {
          font-size: 2.8rem;
          font-weight: 100;
          margin: 5rem 0 7rem 0;
          letter-spacing: 0.2rem;

          @media only screen and (max-width: 900px) {
            font-size: 4rem;
          }

          @media only screen and (max-width: 500px) {
            text-align: center;
          }
        }
      }

      h2 {
        margin-top: 4rem;
        font-size: 2rem;
        color: var(--grey-main);
        letter-spacing: 0.1em;
        font-weight: 600;

        @media only screen and (max-width: 900px) {
          font-size: 3rem;
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: currentColor;
        margin-top: 7rem;
      }

      .quote {
        font-size: 20rem;
        line-height: 1;
        font-style: italic;
        display: block;
        margin-top: 3rem;
        margin-left: -2rem;

        @media only screen and (max-width: 500px) {
          font-size: 14rem;
          margin-left: -1rem;
        }
      }

      .endQuote {
        display: block;
        width: 8rem;
        margin-left: auto;
        margin-top: -5rem;
        position: relative;
        right: 0;

        @media only screen and (max-width: 500px) {
          margin-left: -1rem;
          margin-top: -9.5rem;
        }
      }

      .quoteText {
        transform: translateY(-9rem);

        p:not(:first-child) {
          margin-top: 1.5rem;
        }
      }

      p {
        font-size: 2.2rem;

        @media only screen and (max-width: 900px) {
          font-size: 2.8rem;
        }

        span {
          font-weight: 900;
          margin-top: 0.4em;
        }

        .underline {
          font-weight: 100;
          text-decoration: underline;
        }

        .italic {
          font-weight: 100;
          font-style: italic;
        }
      }

      cite {
        height: 0;
        opacity: 0;
        display: none;
        visibility: hidden;
      }

      .name {
        position: relative;
        font-style: italic;
        margin-top: -10rem;
        display: block;

        @media only screen and (max-width: 500px) {
          margin-top: -6rem;
        }
      }

      .nav {
        margin-top: 12rem;
        display: flex;
        justify-content: space-between;
        text-transform: capitalize;

        .back,
        .forward {
          text-transform: uppercase;
        }

        .back {
          &::before {
            content: "";
            display: inline-block;
            margin-right: 2rem;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
            border-left: 2px solid black;
            border-bottom: 2px solid black;
            transition: 0.2s ease-out;
          }

          &:hover::before {
            margin-right: 3rem;
          }

          @media screen and (max-width: 700px) {
            font-size: 18px;
            &::before {
              margin-right: 1.5rem;
              width: 12px;
              height: 12px;
            }

            &:hover::before {
              margin-right: 2rem;
            }
          }

          @media screen and (max-width: 500px) {
            font-size: 18px;
            &::before {
              margin-right: 1rem;
              width: 8px;
              height: 8px;
              border-width: 1px;
            }

            &:hover::before {
              margin-right: 2rem;
            }
          }
        }

        .forward {
          &::after {
            content: "";
            display: inline-block;
            margin-left: 2rem;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
            border-right: 2px solid black;
            border-top: 2px solid black;
            transition: 0.2s ease-out;
          }

          &:hover::after {
            margin-left: 3rem;
          }

          @media screen and (max-width: 700px) {
            font-size: 18px;
            &::after {
              margin-left: 1.5rem;
              width: 12px;
              height: 12px;
            }

            &:hover::after {
              margin-left: 2rem;
            }
          }

          @media screen and (max-width: 500px) {
            font-size: 18px;
            &::after {
              margin-left: 1rem;
              width: 8px;
              height: 8px;
              border-width: 1px;
            }

            &:after::before {
              margin-left: 2rem;
            }
          }
        }
      }

      .flourishContainer {
        display: flex;
        justify-content: center;
      }

      .socials {
        margin-top: -5.6rem;
        height: 3.8rem;
        overflow: visible;

        //disable
        display: none;
        opacity: 0;
        height: 0;
        width: 0;
        visibility: hidden;
      }
    }
  }
}
