.modalScreen {
  --circle-d: 18rem;
  --top-strip-height: 18rem;
  --cross-height: 33px;

  @media only screen and (max-width: 700px) {
    --top-strip-height: 14rem;
  }

  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: scroll !important;
  overscroll-behavior-y: none !important;
  z-index: var(--modal-z);
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 700px) {
    padding: 0 !important;
    margin: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal {
    --mobile-padding-x: 12rem;

    @media screen and (max-width: 600px) {
      --mobile-padding-x: 10rem;
    }

    @media screen and (max-width: 500px) {
      --mobile-padding-x: 6rem;
    }

    @media screen and (max-width: 500px) {
      --mobile-padding-x: 4rem;
    }

    position: relative;
    z-index: var(--modal-z);
    width: 100%;
    max-width: var(--max-content-width);
    margin: 0 auto;
    animation: fadeIn 0.2s backwards ease-out;
    transition: all 0.1s ease-in;

    @media only screen and (max-width: 700px) {
      margin: 0 auto;
    }

    &.unmounting {
      opacity: 0;
    }

    .crossContainer {
      position: fixed;
      height: var(--cross-height);
      left: 4rem;
      top: 4rem;
      z-index: var(--modal-cross-z);
      cursor: pointer;

      @media only screen and (max-width: 900px) {
        left: calc(var(--section-padding-x) / 2);
        left: 2%;
      }

      @media only screen and (max-width: 700px) {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8rem;
        width: 8rem;
        border-radius: 50%;
        top: calc(var(--top-strip-height) / 2);
        transform: translate3d(0, -50%, 0);
        transition: all 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }

    .playerWrapper {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
      transform: translateY(-50px);
    }

    .reactPlayer {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
