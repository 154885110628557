@keyframes enter {
  from {
    opacity: 0;
    // transform: translateY(5rem);
  }
  to {
    opacity: 1;
    // transform: translateY(0);
  }
}

.select {
  display: block;
  text-align: center;
  margin-top: 1.5rem;
  animation: enter 0.3s ease-in;
}

.productSelect {
  display: flex;
  flex-flow: row wrap;
  margin-top: 4rem;
  animation: enter 0.3s 0.2s ease-in backwards;

  .product {
    position: relative;
    flex: 1 1 25%;
    max-width: 25%;
    width: auto;
    padding-top: 3rem;
    transition: all 0.2s ease-out;

    @media screen and (max-width: 800px) {
      flex: 1 1 33%;
      max-width: 33%;
    }

    @media screen and (max-width: 500px) {
      flex: 1 1 50%;
      max-width: 50%;
    }

    &:not(.cs) {
      &:hover {
        transform: scale(1.05);

        span {
          visibility: visible;
          //   transform: translateY(-1.3rem);

          &.view {
            @media (hover: none) {
              opacity: 0 !important;
              visibility: hidden;
            }
          }
        }
      }
    }

    span {
      // position: absolute;
      display: block;
      text-align: center;
      font-size: 16px;
      font-style: italic;
      // opacity: 0;
      visibility: hidden;
      // transition: opacity .2s, transform .4s;
      //margin-top: -1rem;

      &.view {
        @media (hover: none) {
          opacity: 1;
          visibility: visible !important;
          transform: translateY(-1.2rem);
        }
      }

      &.buy {
        // top: 105%;
        font-style: normal;
        color: var(--pink-main);
        transition: color 0.2s, opacity 0.2s, transform 0.4s,
          letter-spacing 0.2s;
        background-color: var(--grey-main);
        width: 80%;
        margin: 0 auto;
        border: 1px solid var(--pink-main);

        @media (hover: none) {
          opacity: 1;
          // transform: translateY(-1.3rem);
          visibility: visible;
        }

        &:hover {
          color: white;
          letter-spacing: 0.02em;
        }
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .cs {
    position: relative;

    &::after {
      content: "COMING SOON";
      display: block;
      position: absolute;
      font-size: 14px;
      top: 56%;
      left: 50%;
      transform: translateX(-50%);
      width: 98%;
      text-align: center;
      color: var(--pink-main);
      border: 1px solid var(--pink-main);
      background-color: var(--grey-main);
      padding-top: 0.2rem;
      box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.73);
      white-space: nowrap;
    }

    img {
      filter: brightness(0.5);
    }

    .view,
    .buy {
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }
}
