.header {
  --rect-ratio: 35vh;
  --circle-height: 37rem;
  --eg-font-size: var(--font-size-lrg);
  --ca-font-size: var(--font-size-med);

  --landing-animation-time: 2s;

  @media only screen and (max-width: 900px), (max-height: 650px) {
    --rect-ratio: 45vh;
  }

  position: relative;
  // height: 100vh;
  // background-image: linear-gradient(
  //   to top,
  //   var(--pink-main) var(--rect-ratio),
  //   rgba(0, 0, 0, 0) var(--rect-ratio)
  // );
  padding-bottom: calc(var(--section-padding-x) / 4) !important;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--pink-main);
    z-index: var(--header-backing-z);
  }

  @media only screen and (max-width: 700px) {
    padding: 6rem !important;
  }

  --img-origin-x: -100px;
  --img-origin-y: -500px;

  @media only screen and (max-width: 1600px) {
    --img-origin-y: -400px;
  }

  @media only screen and (max-width: 900px) {
    --img-origin-x: calc(-100px * 0.6);
    --img-origin-y: calc(-400px * 0.6);
  }

  @keyframes shrinkIn {
    from {
      // transform: scale(1.1) translate(var(--img-origin-x), var(--img-origin-y));
      transform: scale(1.1);
      opacity: 0;
    }
    to {
      // transform: scale(1) translate(var(--img-origin-x), var(--img-origin-y));
      transform: scale(1);
      opacity: 1;
    }
  }

  .hero {
    position: absolute;
    top: 0;
    left: 0;
    // transform: translate(var(--img-origin-x), var(--img-origin-y));
    width: 100%;
    height: 100%;
    // min-width: calc(100vw - var(--img-origin-x));
    // max-width: 10000px;
    // height: auto;
    // min-height: calc(
    //   var(--img-origin-y) * -1 + 100vh - var(--rect-ratio) + 5px
    // );
    object-fit: cover;
    z-index: var(--hero-z);
    background-color: var(--grey-main);

    // @media only screen and (max-width: 1600px) {
    //   width: 1700px;
    // }

    @media only screen and (max-width: 900px) {
      // width: 1400px;
      // --img-origin-y: -350px;
      // --img-origin-x: -250px;
      height: 60%;
    }

    // @media only screen and (max-width: 500px) {
    //   width: 800px;
    //   --img-origin-y: -350px;
    //   --img-origin-x: -200px;
    // }
  }

  .headerContent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    grid-template-columns: 1fr var(--circle-height);
    grid-template-rows:
      calc(
        100vh - var(--section-padding-y) - var(--rect-ratio) -
          var(--circle-height) / 2
      )
      repeat(2, calc(var(--circle-height) / 2));
    grid-template-areas:
      "top-spacer top-spacer"
      "eg circle"
      "bottom circle"
      "bottom-spacer bottom-spacer";
    width: 100%;
    height: 100%;
    // position: relative;

    @keyframes inRight {
      from {
        transform: translateX(-5rem);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes inLeft {
      from {
        transform: translateX(5rem);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes inDown {
      from {
        transform: translateY(-20%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes inUp {
      from {
        transform: translateY(20%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes growIn {
      from {
        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
      }
      to {
        opacity: 1;
        transform: scale(1, 1, 1);
      }
    }

    .top {
      text-align: center;
      margin: 10rem 0;

      @media only screen and (max-width: 900px) {
        margin: 17rem 0;
      }

      h1 {
        font-size: var(--eg-font-size);
        line-height: 0.9;
        font-weight: 600;
        letter-spacing: 2px;
        color: var(--white);
        margin-top: 2rem;
        margin-bottom: 1rem;
        width: 100%;
      }

      h2 {
        font-size: var(--ca-font-size);
        line-height: 5rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: var(--white);

        @media only screen and (max-width: 900px) {
          margin: 17rem 0;
          opacity: 0;
        }
      }
    }

    .circle {
      height: var(--circle-height);
      width: var(--circle-height);
      max-height: 25rem;
      max-width: 25rem;
      grid-area: circle;

      @media only screen and (max-width: 900px) {
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
      }
      @media only screen and (max-width: 400px) {
        --circle-height: 22rem;
      }
    }

    @media only screen and (max-width: 900px) {
      --eg-font-size: 7rem;
      --ca-font-size: 4rem;

      max-height: 100vh;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;

      .top {
        text-align: center;
        align-self: center;
        flex-basis: calc(
          100vh - var(--rect-ratio) - (var(--circle-height) / 2) - 7rem
        ); // 1rem offset not understood
        margin-bottom: 0;
        display: flex;
        align-items: flex-end;

        @media only screen and (max-width: 900px) {
          flex-direction: column;
          align-items: center;
        }

        h1 {
          margin-bottom: 42rem;

          @media only screen and (max-height: 630px) {
            margin-bottom: 2rem;
          }

          @media only screen and (max-height: 400px) {
            margin-bottom: 10rem;
          }
        }
      }

      .circle {
        align-self: auto;
        max-width: 27rem;
        max-height: 27rem;
      }

      .bottom {
        order: 2;
        margin-top: 5rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        flex-grow: 1;
        max-height: 18rem;

        @media only screen and (max-height: 630px) {
          margin-top: 2rem;
        }

        h2 {
          text-align: center;
        }

        svg {
          align-self: center;
          margin-bottom: none;
        }
      }
    }

    @media only screen and (max-width: 700px) {
      .top {
        flex-basis: calc(
          100vh - var(--rect-ratio) - (var(--circle-height) / 2) - 1rem
        ); // 1rem offset unknown
      }
    }

    @media only screen and (max-width: 400px) {
      .top {
        flex-basis: calc(
          100vh - var(--rect-ratio) - (var(--circle-height) / 2) + 1.5rem
        ); // 1rem offset unknown
      }
    }

    @media only screen and (max-width: 375px) {
      --ca-font-size: 3.4rem;
    }

    @media only screen and (max-width: 350px) {
      --eg-font-size: 6rem;
    }
  }

  //Before Load
  opacity: 0;

  //After load
  &.loaded {
    opacity: 1;

    .hero {
      animation: shrinkIn var(--landing-animation-time) ease-out;
    }

    .top {
      animation: inRight var(--landing-animation-time) ease-out;

      @media screen and (max-width: 900px) {
        animation: inDown var(--landing-animation-time) ease-out;
      }

      h2 {
        @media screen and (max-width: 900px) {
          opacity: 1;
          transition: opacity 1s 1.2s;
        }
      }
    }

    .bottom {
      svg {
        animation: inRight var(--landing-animation-time) 0.4s ease-out backwards;

        @media screen and (max-width: 900px) {
          animation: none;
        }
      }

      @media screen and (max-width: 900px) {
        animation: inUp var(--landing-animation-time) ease-out;
      }
    }

    .circle {
      animation: inLeft var(--landing-animation-time) ease-out;

      @media screen and (max-width: 900px) {
        animation: growIn var(--landing-animation-time) ease-out;
      }
    }
  }
}
