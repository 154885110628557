@keyframes fallIn {
  from {
    opacity: 0;
    transform: translate3d(0, -5px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.article {
  --line-height: 3.7rem;

  //overflow: hidden;
  padding: 4rem 0;
  border-bottom: 1px solid black;
  display: flex;
  flex-flow: row wrap;
  animation: fallIn 1s ease-out backwards;

  --margin: 2.5rem;

  .date {
    color: var(--pink-main);
    flex: 2;
    min-width: 130px;

    .month {
      font-size: 3rem;
      margin-bottom: var(--margin);
    }
    .day {
      font-size: 12rem;
      line-height: 1;
    }
  }

  .text {
    flex: 7;
    min-width: 70%; //change this to define when to wrap

    h2 {
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: var(--margin);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 519px) {
        white-space: initial;
        margin-top: 4rem;
      }
    }

    p {
      position: relative;
      font-size: 2.4rem;
      line-height: var(--line-height);
      height: calc(3 * var(--line-height));
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 519px) {
        height: auto;
        max-height: calc(7 * var(--line-height));
      }
    }
  }

  .linkContainer {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
    margin-top: 1rem;

    @media screen and (max-width: 519px) {
      margin-top: 3rem;
    }

    .spacer {
      flex: 1;
    }

    button {
      padding: 1rem 3rem;
      background-color: var(--grey-lightest);
      font-weight: 100;
      transition: all 0.2s;

      &:hover {
        opacity: 0.7;
        color: #000000;
        box-shadow: -3px 9px 13px -2px rgba(0, 0, 0, 0.29);
        transform: translate3d(0, -2px, 0);
      }
    }
  }
}
