:root {
  /* hack to correct safari rem issue */
  --std-browser-font-size-sim: 16px;

  /* Colours */
  --white: #ffffff;
  --blush-white: #e7e3e1;
  --off-white: #f6f4f3;
  --pink-main: #ecc0c6;
  --pink-main-rgb: 236, 192, 198;
  --grey-main: #414042;
  --grey-main-rgb: 65, 64, 66;
  --grey-mid: #545355;
  --grey-light: #979797;
  --grey-lighter: #baafad;
  --grey-lightest: #e7e4e5;

  /* Font Sizes */
  --font-size-lrg: 5.5rem;
  --font-size-med: 3rem;
  --font-size-std: 2.4rem;

  /* Line Height */
  --line-height-article-head: 1.4;

  /*fonts*/
  --font-std: meno-banner, serif;

  /* Layout */
  --max-content-width: 96rem;
  --section-padding-x: 15rem;
  --section-padding-y: 15rem;

  --header-backing-z: -3;
  --hero-z: -2;
  --hamburger-z: 1;
  --backdrop-z: 2;
  --scroll-up-z: 3;
  --nav-z: 4;
  --hamburger-active-z: 5;
  --modal-z: 6;
  --modal-cross-z: 7;
}

html {
  position: relative;
  font-size: 62.5%;
}

body {
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  min-width: 100%;
  font-size: var(--font-size-std);
  line-height: 1.6;
  color: var(--grey-main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-stroke: 0;
}

body,
input {
  font-family: var(--font-std);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a:link,
a:visited {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

input,
textarea,
button {
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  outline: none;
}

button {
  font-family: var(--font-std);
  font-weight: 600;
  cursor: pointer;
}

figcaption {
  font-size: 1.6rem;
  font-style: italic;
}

svg {
  overflow: visible;
}

@media only screen and (max-height: 650px) {
  :root {
    --section-padding-y: 8rem;
  }
}

@media only screen and (max-width: 1150px) {
  html {
    font-size: calc(var(--std-browser-font-size-sim) * 0.525);
  }
}

@media only screen and (max-width: 900px) {
  :root {
    --section-padding-y: 12rem;
    --section-padding-x: 8rem;
    --font-size-med: 4rem;
    --font-size-std: 3rem;
  }

  html {
    font-size: calc(var(--std-browser-font-size-sim) * 0.45);
  }
}

@media only screen and (max-width: 700px) {
  :root {
    --section-padding-x: 6rem;
  }
}

@media only screen and (max-height: 500px) {
  html {
    font-size: calc(var(--std-browser-font-size-sim) * 0.375);
  }
}

.ytp-chrome-top {
  display: none !important;
  visibility: hidden !important;
}
