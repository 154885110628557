.pinecone {
  float: right;
  clear: right;
  height: 23.8rem;
  width: 19.3rem;
  border-radius: 100%;
  top: 50px;
  shape-outside: circle(50%);
  transform: translateY(7rem);

  @media only screen and (max-width: 500px) {
    height: 0;
    width: 0;
    display: none;
  }
}
