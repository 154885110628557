@keyframes expandRight {
  from {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fallIn {
  from {
    transform: translate3d(0, -15px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.header {
  --wait: 0.2s;
  --main-t: 1.5s;
  --secondary-t: 0.8s;
  --height: 17rem;

  position: relative;
  height: var(--height);
  background-color: var(--pink-main);
  padding: 0 var(--section-padding-x);
  animation: expandRight var(--main-t) var(--wait) backwards;

  // & > * {
  //   animation: fallIn var(--secondary-t) calc(var(--main-t) + var(--wait) + .2s) ease-out backwards;

  //   &.circle {
  //     animation: fallIn var(--secondary-t) calc(var(--main-t) + var(--wait) + .5s) ease-out backwards;
  //   }
  // }

  .content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: var(--max-content-width);
    margin: 0 auto;

    .text {
      line-height: 1;
      text-align: center;
      animation: fallIn var(--secondary-t) calc(var(--main-t) + var(--wait))
        ease-out backwards;

      @media screen and (max-width: 550px) {
        width: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        display: none;
      }

      h1 {
        color: var(--white);
        font-size: 3.5rem;
        font-weight: normal;
        letter-spacing: 0.05em;
      }

      h2 {
        font-weight: 600;
        font-size: 2.4rem;
      }
    }

    .circle {
      position: absolute;
      top: calc(var(--height) / 2);
      right: 0;
      width: 11rem;
      height: 11rem;
      transform: translateY(-50%);

      @media screen and (max-width: 550px) {
        position: relative;
        margin: 0 auto;
        transform: translateY(-83%);
        width: 10rem;
        height: 10rem;
      }

      .innerCircle {
        width: 100%;
        height: 100%;
        animation: fallIn var(--secondary-t)
          calc(var(--main-t) + var(--wait) + 0.4s) ease-out backwards;
      }
    }
  }
}
