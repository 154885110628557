:root {
  --article-font-size-basis: var(--font-size-std);
}

@media screen and (max-width: 450px) {
  :root {
    --article-font-size-basis: 2.6rem;
  }
}

.has-small-font-size {
  font-size: calc(var(--article-font-size-basis) * 0.9) !important;
}

.has-large-font-size {
  font-size: calc(var(--article-font-size-basis) * 1.3) !important;
}

.has-larger-font-size {
  font-size: calc(var(--article-font-size-basis) * 1.6) !important;
}

.has-inline-color,
.has-accent-color {
  color: var(--pink-main);
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-right {
  text-align: right;
}

.has-background {
  padding: 1rem;
}
