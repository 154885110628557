.errorPage {
  height: 100vh;
  width: 100vw;
  background-color: var(--pink-main);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../../res/img/EGN_treeline-temp.png");
  background-size: cover;

  & > div {
    display: flex;
    width: 27rem;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 9rem;
      line-height: 1;
    }

    & > div {
      width: 10rem;
      height: 10rem;
      transform: translateY(-0.5rem);
    }
  }

  h3 {
    font-size: 2.5rem;
    margin-top: 2rem;
    letter-spacing: 0.15em;

    @media screen and (max-width: 500px) {
      margin-top: 4rem;
      letter-spacing: 0.2em;
      font-size: 2.3rem;
    }
  }
}
