.eg {
  --circle-d: 30rem;

  // float: right;
  // clear: right;
  height: var(--circle-d);
  width: var(--circle-d);
  border-radius: 100%;
  shape-outside: circle(50%);
  margin: 3rem;
  position: relative;
  left: 50%;
  transform: translateX(calc(-50% - 3rem));

  @media only screen and (max-width: 750px) {
    height: 25rem;
    width: 25rem;
  }

  @media only screen and (max-width: 700px) {
    position: absolute;
    top: 10rem;
  }

  @media only screen and (max-width: 382px) {
    top: 16rem;

    @media only screen and (max-height: 500px) {
      top: 10rem;
    }
  }
}

.head {
  @media only screen and (max-width: 700px) {
    margin-bottom: 40rem !important;
  }
}
