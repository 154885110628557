.backdrop {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  height: 100vh;
  width: 100%;
  max-width: 100vw !important;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--backdrop-z);
  background-color: rgba(#000, 0.9);
  animation: fadeIn 0.1s linear;
  overscroll-behavior-y: contain;

  &.unmounting {
    animation: fadeOut 0.1s linear forwards;
  }
}
