.hamburgerContainer {
  position: absolute;
  top: 5rem;
  left: 0;
  display: block;
  height: 50px;
  width: 100%;
  padding: 0 var(--section-padding-x) 0 var(--section-padding-x) !important;

  & > div {
    max-width: var(--max-content-width);
    margin: 0 auto;
  }

  @media only screen and (max-height: 750px) {
    top: 4rem;
  }
}

.navScreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: var(--nav-z);
  overscroll-behavior: contain;
  padding: var(--section-padding-y) var(--section-padding-x);

  --circle-d: 12rem;

  @media only screen and (max-height: 750px) {
    --circle-d: 8rem;
  }

  @media only screen and (max-height: 500px) {
    --circle-d: 10rem;
  }

  .circle {
    position: absolute;
    right: 8rem;
    top: var(--top-height);
    transform: translateY(-50%);
    height: var(--circle-d);
    width: var(--circle-d);
  }

  .navContainer {
    position: relative;
    z-index: var(--nav-z);
    color: var(--white);
    z-index: 101;
    transform: translateY(10%);
    max-width: var(--max-content-width);
    margin: 0 auto;

    @media only screen and (max-width: 900px) {
      margin-top: 6rem;
    }

    @media only screen and (max-height: 680px) {
      margin-top: 0;
      font-size: 27px;
    }

    @media only screen and (max-height: 600px) {
      margin-top: -5rem;
    }

    @media only screen and (max-width: 350px) {
      font-size: 22px;
    }

    @keyframes fallIn {
      from {
        opacity: 0;
        transform: translateY(-30px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .nav {
      display: flex;
      flex-flow: column nowrap;
      overflow: visible;

      li {
        align-self: flex-start;
        display: inline-block;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          padding-left: 3px;
          letter-spacing: 0.3px;
        }

        --time: 0.5s;
        --delay: 0.3s;
        --interval: 0.07s;

        &:nth-child(1) {
          animation: fallIn var(--time) calc(var(--delay) + 0 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(2) {
          animation: fallIn var(--time) calc(var(--delay) + 1 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(3) {
          animation: fallIn var(--time) calc(var(--delay) + 2 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(4) {
          animation: fallIn var(--time) calc(var(--delay) + 3 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(5) {
          animation: fallIn var(--time) calc(var(--delay) + 4 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(6) {
          animation: fallIn var(--time) calc(var(--delay) + 5 * var(--interval))
            backwards ease-out;
        }
        &:nth-child(7) {
          animation: fallIn var(--time) calc(var(--delay) + 5 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(8) {
          animation: fallIn var(--time) calc(var(--delay) + 6 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(9) {
          animation: fallIn var(--time) calc(var(--delay) + 7 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(10) {
          animation: fallIn var(--time) calc(var(--delay) + 8 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(11) {
          animation: fallIn var(--time) calc(var(--delay) + 9 * var(--interval))
            backwards ease-out;
        }

        &:nth-child(12) {
          animation: fallIn var(--time)
            calc(var(--delay) + 10 * var(--interval)) backwards ease-out;
        }

        &:nth-child(13) {
          animation: fallIn var(--time)
            calc(var(--delay) + 11 * var(--interval)) backwards ease-out;
        }
      }

      .divider {
        --div-width: 31rem;

        display: block;
        flex: 0 0 1px;
        height: 1px;
        width: var(--div-width);
        background-color: var(--pink-main);
        margin: 1.5rem 0;
        animation: stretchIn 1.2s 1.1s backwards;

        @keyframes stretchIn {
          from {
            width: 0;
          }
          to {
            width: var(--div-width);
          }
        }

        @media only screen and (max-width: 900px) {
          --div-width: 38rem;
        }

        @media only screen and (max-width: 359px) {
          --div-width: 33rem;
        }
      }

      .articleLnk {
        color: var(--pink-main);
      }

      .socials {
        display: flex;
        flex-flow: row nowrap;
        cursor: pointer;

        //disable
        display: none;
        opacity: 0;
        height: 0;
        width: 0;
        visibility: hidden;

        & > *:not(:last-child) {
          margin-right: 1rem;
        }

        .igContainer,
        .fbContainer {
          animation-duration: 1s;
          animation-timing-function: ease-in;
          transition-duration: 0.4s;

          &:hover {
            opacity: 0.7;
          }
        }

        .igContainer {
          animation-delay: 1.2s;
        }

        .fbContainer {
          animation-delay: 1.5s;
        }
      }
    }
  }

  //Mobile View
  @media only screen and (max-width: 450px) {
    --top-height: 52px;

    @media only screen and (max-height: 750px) {
      --top-height: 45px;
    }

    @media only screen and (max-height: 500px) {
      --top-height: 40px;
    }

    background-image: linear-gradient(
      to bottom,
      var(--pink-main) var(--top-height),
      var(--blush-white) var(--top-height)
    );
    font-size: 4rem;
    line-height: 1.4;

    .navContainer {
      .nav {
        color: var(--grey-main);

        .divider {
          align-self: stretch;
          width: 37.5rem;
        }

        .articleLnk {
          color: currentColor;
        }
      }
    }

    @media only screen and (max-width: 359px) {
      font-size: 3.5rem;

      @media only screen and (max-height: 500px) {
        font-size: 4rem;
      }
    }
  }
}
