.videos {
  max-width: calc(100vw - 2 * var(--section-padding-x));
  margin: 0 auto;
  padding: 8rem 0;

  h1 {
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 400;
    letter-spacing: 0.1em;
  }

  .flourishContainer {
    display: flex;
    justify-content: center;
  }

  .divider {
    margin: 8rem 0;
    height: 1px;
    width: 100%;
    background-color: currentColor;
  }

  .listContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .videoContainer {
      flex: 0 0 45%;
      max-width: 45%;
      margin-bottom: 6rem;
      border-bottom: 1px solid currentColor;

      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .titleContainer {
        display: flex;
        align-items: flex-start;
        margin-bottom: 3rem;
        // border: 1px solid red;

        @media screen and (max-width: 900px) {
          margin-bottom: 2rem;
        }

        @media screen and (max-width: 600px) {
          height: auto;
        }

        h2 {
          @media screen and (max-width: 900px) {
            font-size: 18px;
          }

          @media screen and (max-width: 600px) {
            font-size: 20px;
          }
        }

        .vidTitle {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-weight: 600;
          margin: 0;
          //border: 1px solid red;
        }
      }

      .thumb {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        overflow: hidden;
        margin-bottom: 1rem;

        img {
          width: 100%;
          height: auto;
          position: absolute;
          top: 0;
          left: 0;
          transform: translateY(-12.55%);
        }
      }

      .sub {
        padding-bottom: 1rem;
        display: flex;
        justify-content: flex-end;

        span {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.1em;

          @media screen and (max-width: 900px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .error {
    text-align: center;
    padding-bottom: 8rem;
    border-bottom: 1px solid currentColor;
  }
}
