.articlesPage {
  overflow: hidden;

  & > * > * {
    max-width: var(--max-content-width);
    margin: 0 auto;
    overflow: hidden;
  }

  .articles {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 10rem var(--section-padding-x);
    padding-bottom: 0;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes fallIn {
      from {
        opacity: 0;
        transform: translate3d(0, -5px, 0);
      }
      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    .head {
      display: flex;
      width: 100%;
      flex-flow: column nowrap;
      align-items: center;
      padding: 15rem;
      padding-bottom: 10rem;

      &.loaded {
        border-bottom: 1px solid currentColor;
        animation: fallIn 1s ease-out backwards;
        padding: 0;
      }

      @keyframes expand {
        from {
          letter-spacing: 2px;
        }
        to {
          letter-spacing: 4px;
        }
      }

      h1 {
        font-size: 3rem;
        margin-bottom: 4rem;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-align: center;

        &.loading {
          animation: expand 7s ease-in-out infinite alternate,
            halfFadeIn 1s infinite ease-out alternate;

          //so animation doesnt wrap mid animation
          @media screen and (min-width: 546px) {
            white-space: nowrap;
          }
        }
      }

      @keyframes vert {
        0% {
          transform: translate3d(0, -10px, 0);
        }
        100% {
          transform: translate3d(0, 10px, 0);
        }
      }

      @keyframes hor {
        0% {
          transform: translate3d(0px, 0, 0);
        }
        25% {
          transform: translate3d(-20px, 0, 0);
        }
        75% {
          transform: translate3d(20px, 0, 0);
        }
        100% {
          transform: translate3d(px, 0, 0);
        }
      }

      @keyframes hor {
        0% {
          transform: translate3d(-10px, 0, 0);
        }
        100% {
          transform: translate3d(10px, 0, 0);
        }
      }

      @keyframes rot {
        from {
          transform: rotate(7deg);
        }
        to {
          transform: rotate(-7deg);
        }
      }

      @keyframes halfFadeIn {
        from {
          opacity: 0.2;
        }
        to {
          opacity: 1;
        }
      }

      --base: 0.6s;

      .animate1 {
        margin-bottom: 7rem;
        //animation: vert var(--base) infinite ease-in alternate;
        animation: halfFadeIn 1s infinite ease-out alternate;
      }

      .animate2 {
        // animation: hor calc(var(--base) * 5) infinite ease-in-out alternate;
      }

      .animate3 {
        //animation: rot calc(var(--base) * 2.2) ease-in-out infinite alternate;
      }

      svg {
        margin-bottom: 7rem;
      }
    }

    .list {
      width: 100%;
    }
  }

  .articleState {
    display: block;
    width: 30rem;
    font-size: 3rem;
    background-color: transparent;
    color: var(--grey-main);
    margin: 8rem auto 20rem auto;
    cursor: pointer;
    transition: all 0.2s;

    @media screen and (min-width: 900px) {
      &:hover {
        color: var(--pink-main);
        opacity: 0.6;
      }
    }

    &.loading {
      color: inherit;
      animation: halfFadeIn 0.8s ease-out infinite alternate;

      &:hover {
        opacity: 1;
      }
    }

    &.disabled {
      cursor: default;

      &:hover {
        color: inherit;
        opacity: 1;
      }
    }
  }

  .error {
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 3rem;
    padding: 3rem;
    border: 3px double black;
    margin: 9rem 0;
  }
}
