.LandingPage {
  position: relative;
  overflow: hidden;
  width: 100vw;
  min-width: 100vw;

  .treeline {
    --top: -30rem;
    --height: calc(120% - var(--top));
    --width: 2000px;

    @media only screen and (max-width: 1150px) {
      --top: -50rem;
      --height: calc(150% - var(--top));
    }

    @media only screen and (max-width: 900px) {
      left: -200px;
    }

    @media only screen and (max-width: 650px) {
      --top: -45rem;
      --height: calc(200% - var(--top));
      --width: 1000px;
    }

    @media only screen and (max-width: 400px) {
      --top: -30rem;
      --height: calc(200% - var(--top));
      --width: 1000px;
    }

    position: absolute;
    top: var(--top);
    left: 0;
    height: var(--height);
    min-width: var(--width);
    width: 100%;
  }

  .mapSpacer {
    height: 400px;
  }
}

.LandingPage > * {
  padding: var(--section-padding-y) var(--section-padding-x);
  overflow: hidden;

  & > *:not(img) {
    max-width: var(--max-content-width);
    margin: 0 auto;
  }
}
