.flourishContainer {
  margin: 8rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: 450px) {
    opacity: 0;
    margin-bottom: 0;
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: currentColor;
  margin-top: 10rem;
}

.bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 10rem 0 0 0;
  text-align: right;

  @media only screen and (max-width: 750px) {
    flex-flow: column nowrap;
    align-items: stretch;
    text-align: left;
  }

  .right {
    @media only screen and (max-width: 750px) {
      margin-top: 6rem;
    }

    p {
      margin: 0;
      color: var(--grey-mid);
      margin-bottom: 0 !important;
      transition: all 0.2s ease-in;

      &:hover {
        opacity: 0.6;
      }

      @media only screen and (max-width: 750px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 350px) {
        font-size: 14px;
      }

      &:not(:last-child) {
        color: var(--grey-lighter);
      }
    }
  }
}

.logo {
  transition: all 0.2s ease-in;

  &:hover {
    opacity: 0.6;
  }

  @media only screen and (max-width: 500px) {
    width: 25rem;
  }
}
