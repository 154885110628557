.container {
  position: relative;
  height: 50rem;
  overflow: hidden;
  padding: 0 !important;

  background-color: var(--pink-main);

  @media only screen and (max-width: 650px) {
    height: 35rem;
  }

  @media only screen and (max-width: 400px) {
    height: 28rem;
  }

  & > * {
    max-width: 100vw !important;
  }

  .treeline {
    --top: 50%;
    --height: auto;
    --width: 100%;

    @media only screen and (max-width: 900px) {
      --width: 150%;
    }

    @media only screen and (max-width: 500px) {
      --width: 200%;
      top: 35%;
    }

    position: absolute;
    top: var(--top);
    left: 0;
    height: var(--height);
    min-width: var(--width);
    width: 100%;
    filter: blur(5px);
    transition: filter 0.2s ease-in;

    &.loaded {
      filter: none;
    }
  }
}
