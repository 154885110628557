@keyframes enter {
  from {
    opacity: 0;
    // transform: translateY(5rem);
  }
  to {
    opacity: 1;
    // transform: translateY(0);
  }
}

.product {
  animation: enter 0.3s 0.2s ease-out backwards;

  .pdf {
    font-size: 0.9em;
    text-decoration: underline;
    font-style: italic;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: -5rem;
    margin-top: 2rem;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  .error {
    font-size: 3rem;
    font-weight: 400;
    margin: 7rem auto;
    position: relative;
    display: block;
    text-align: center;
  }

  .viewAll {
    font-size: 18px;
    letter-spacing: 0.05em;
    display: block;
    text-align: center;
    margin: 1rem 0 4rem 0;
    text-decoration: underline;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;

    .back,
    .forward {
      @media screen and (max-width: 500px) {
      }
    }

    .back {
      &::before {
        content: "";
        display: inline-block;
        margin-right: 2rem;
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
        border-left: 2px solid black;
        border-bottom: 2px solid black;
        transition: 0.2s ease-out;
      }

      &:hover::before {
        margin-right: 3rem;
      }

      @media screen and (max-width: 700px) {
        font-size: 18px;
        &::before {
          margin-right: 1.5rem;
          width: 12px;
          height: 12px;
        }

        &:hover::before {
          margin-right: 2rem;
        }
      }

      @media screen and (max-width: 500px) {
        font-size: 18px;
        &::before {
          margin-right: 1rem;
          width: 8px;
          height: 8px;
          border-width: 1px;
        }

        &:hover::before {
          margin-right: 2rem;
        }
      }
    }

    .forward {
      &::after {
        content: "";
        display: inline-block;
        margin-left: 2rem;
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
        border-right: 2px solid black;
        border-top: 2px solid black;
        transition: 0.2s ease-out;
      }

      &:hover::after {
        margin-left: 3rem;
      }

      @media screen and (max-width: 700px) {
        font-size: 18px;
        &::after {
          margin-left: 1.5rem;
          width: 12px;
          height: 12px;
        }

        &:hover::after {
          margin-left: 2rem;
        }
      }

      @media screen and (max-width: 500px) {
        font-size: 18px;
        &::after {
          margin-left: 1rem;
          width: 8px;
          height: 8px;
          border-width: 1px;
        }

        &:after::before {
          margin-left: 2rem;
        }
      }
    }
  }

  .productName {
    position: relative;
    top: 6rem;
    font-size: 30px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 0.5rem 0 0.3rem 1.7rem;
    z-index: -1;
  }

  .spacer {
    height: 10rem;

    @media screen and (max-width: 540px) {
      height: 16rem;
    }
  }

  .desc {
    min-height: 55rem;

    span {
      display: inline !important;
      font-weight: 400 !important;
    }
  }

  p {
    margin-bottom: 1em;

    margin-top: 0 !important;

    .figure {
      float: right;
      margin-left: 8rem;
      transform: translateY(-2rem);

      @media screen and (max-width: 540px) {
        float: initial;
        transform: translateY(6rem);
        height: 40rem;
        margin: 0 auto;
        display: block;
        padding: 0;
        margin: 8rem auto 4rem auto;
        display: block;
        margin: 8rem auto 4rem auto;
      }

      img {
        height: 50rem;

        @media screen and (max-width: 540px) {
          float: initial;
          height: 40rem;
          margin: 0 auto;
          display: block;
          padding: 0;
        }
      }

      .buyNow {
        display: block !important;
        // max-width: 100%;
        margin: 0 auto;
        border: 1px solid currentColor;
        padding: 0.4rem 0 0.1rem 0;
        text-align: center;
        transition: all 0.1s ease-in;

        &:hover {
          background-color: var(--grey-main);
          color: var(--blush-white);
        }

        @media screen and (max-width: 540px) {
          max-width: 100%;
          margin: 4rem auto 0 auto !important;
        }
      }
    }
  }
}
