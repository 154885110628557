.testimonials {
  color: white;

  .link {
    font-size: 28px;
    border: 1px solid white;
    padding-top: 0.4rem;
    padding-bottom: 0.2rem;
    margin: 0 auto;
    max-width: 40rem;
    text-align: center;
    transition: 0.2s;
    line-height: 1.2;

    @media screen and (max-width: 500px) {
      font-size: 25px;
    }

    p {
      margin-bottom: 7rem;
    }

    &:hover {
      color: var(--grey-main);
      background-color: white;
      opacity: 0.8;
    }
  }
}
