.divider {
  min-height: 40rem;
  margin-bottom: 8rem;

  @media screen and (max-width: 900px) {
    min-height: 50rem;
  }

  .productContainer {
    float: right;
    position: relative;
    width: 23rem;
    height: 40rem;
    margin-left: 4rem;
    margin-bottom: 1rem;
    display: block;

    @media screen and (max-width: 900px) {
      height: 50rem;
      width: 27rem;
    }

    @media screen and (max-width: 500px) {
      float: initial;
      margin: 0 auto;
      margin-bottom: 4rem;
      transform: translateX(-1.5rem);
    }

    & > img {
      height: 100%;
      width: auto;
    }

    & > *:first-child {
      z-index: 0;
      left: 0;
      transform: translateX();
    }

    & > *:last-child {
      z-index: 1;
      position: absolute;
      right: 0;
      transform: translateX(3rem);
    }
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
}
