.faq {
  max-width: calc(100vw - 2 * var(--section-padding-x));
  margin: 0 auto;
  padding: 8rem 0;

  h1 {
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 400;
    letter-spacing: 0.1em;
  }

  .flourishContainer {
    display: flex;
    justify-content: center;
  }

  .faqContainer {
    margin: 5rem 0;
  }
}
