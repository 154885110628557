.set {
  border-bottom: 1px solid currentColor;
  padding: 1rem 0;

  &:first-child {
    border-top: 1px solid currentColor;
  }

  .main {
    display: flex;
    justify-content: space-between;
    transition: margin-bottom 0.3s ease-out;

    .question {
      margin: 0;
    }

    button {
      height: initial;
      width: initial;
      background-color: transparent;
    }

    .toggle {
      position: relative;
      width: 3rem;
      height: 3rem;
      transition: transform 0.4s cubic-bezier(0.61, -0.68, 0.43, 1.54);

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        background-color: currentColor;
      }

      --stroke: 2px;

      &::before {
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 100%;
        width: var(--stroke);
      }

      &::after {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: var(--stroke);
      }
    }
  }

  .answer {
    box-sizing: content-box;
    margin-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s;
    transition: max-height 0.7s ease-out, opacity 0.5s ease-out;
  }

  &.open {
    .answer {
      max-height: 600px;
      opacity: 1;
    }

    .main {
      margin-bottom: 3rem;

      .toggle {
        transform: rotate(135deg);
      }
    }
  }
}
