.button {
  font-size: 28px;
  font-weight: 600;
  padding: 0.5rem 3rem;
  border: none;
  transition: all 0.1s ease-in;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    font-size: 25px;
    padding-top: 8px;
  }
}

.dark {
  background-color: var(--grey-main);
  color: var(--pink-main);

  &:hover {
    background-color: rgba(var(--grey-main-rgb), 0.9);
    color: var(--grey-lightest);
    //padding: .5rem 3.5rem;
    //letter-spacing: .01em
  }
}

.light {
  background-color: var(--pink-main) !important;
  color: var(--grey-main) !important;

  &:hover {
    opacity: 0.8;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
