.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 50rem;

  @keyframes halfFadeIn {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes expand {
    from {
      letter-spacing: 2px;
    }
    to {
      letter-spacing: 4px;
    }
  }

  animation: halfFadeIn 1s infinite ease-out alternate;

  .flourishContainer {
  }

  .subject {
    animation: expand 7s ease-in-out infinite alternate;
    font-size: 3rem;
    margin-bottom: 2rem;
  }
}
