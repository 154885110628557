.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 6rem;
}

.finalSection {
  margin-top: 6rem;
}
