.section {
  padding: var(section-padding-y) var(--section-padding-x);
  position: relative;

  .sectionContent {
    position: relative;
    width: 100%;
    max-width: var(--max-content-width);
    margin: 0 auto;
  }

  h1 {
    margin-bottom: 7rem;
    font-size: var(--font-size-med);
    line-height: var(--line-height-article-head);
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  h2 {
    font-size: var(--font-size-std);
    margin-bottom: 4rem;
  }

  & p {
    margin-bottom: 4rem;
  }
}
