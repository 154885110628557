.base {
  transition-property: all;
  transition-duration: 0.95s;
}

.hidden {
  opacity: 0;
  transform: translateX(-2rem) skewX(-3deg);

  @media screen and (max-width: 500px) {
    transform: translateY(-1.8rem);
  }
}
