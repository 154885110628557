.playerWrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.ytp-chrome-top.ytp-show-cards-title {
  display: none !important;
}

.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10rem;
}
